import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userVerified, setUserVerified] = useState(null);
  const [bearerToken, setBearerToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedBearerToken = localStorage.getItem('bearerToken');
    const storedAccessToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    const storedUserVerified = localStorage.getItem('userverified');

    if (storedUser) {setUser(JSON.parse(storedUser));}
    if (storedBearerToken) {setBearerToken(storedBearerToken);}
    if (storedAccessToken) {setAccessToken(storedAccessToken);}
    if (storedRefreshToken) {setRefreshToken(storedRefreshToken);}
    if (storedUserVerified) {setUserVerified(storedUserVerified);}
  }, []);

  const saveUserToLocalStorage = (user, token) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('bearerToken', token);
  };

  const saveUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  };

  const getinfo = () => {
    console.log(user)
    console.log(userVerified)
    console.log(bearerToken)
  };

  const logout = () => {
    setUser(null);
    setBearerToken(null);
    setAccessToken(null);
    setRefreshToken(null);
    setUserVerified(null);

    localStorage.removeItem('user');
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userverified');
  };

  return (
    <UserContext.Provider value={{ user, setUser, accessToken, setAccessToken, refreshToken, setRefreshToken, bearerToken, setBearerToken, logout, getinfo, saveUserToLocalStorage, saveUser, setUserVerified }}>
      {children}
    </UserContext.Provider>
  );
};
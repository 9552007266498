import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../components/Shared/UserContext';
import { ReactComponent as Iconvideo } from '../../../components/assets/icons/VideoIcon.svg'

import {
  TitleContainer,
  StatisticCard,
  DoughnutChartContainer,
  StatisticInfo,
  StatLabel,
  TestInfo,
  TestName,
  TestValue,
  PercentageLabel,
  ProgressBarContainer,
  StatisticsContainer,
  ProgressBar,
  TestContainer,
  FirstHalf,
  ThirdOne,
  ThirdTwo,
  ThirdThree,
  SecondHalf
} from './StatisticStyles';

const StatisticChart = () => {
  const { playerId } = useParams();
  const { bearerToken } = useUser();
  const [ skills, setSkills ] = useState([]);

  useEffect(() => {
    const loadData = (data) => {
      let data_final = [];
    
      data.forEach((habilidad) => {
        let dataTemplate = {
          label: '',
          value: null,
          color: '',
          backgroundcolor: '',
          tests: [],
        };
    
        dataTemplate.label = habilidad.name;
    
        let maxValueTotal = 0;
    
        if (habilidad.test_set.length !== 0) {
          habilidad.test_set.forEach((prueba) => {
            let testTemplate = {
              name: '',
              value: null,
              color: '',
              backgroundcolor: '',
              videolink: ''
            };

            testTemplate.name = prueba.name;
            let maxValueTest = 0;
            prueba.testresult_set.forEach((resultado_prueba) => {
              testTemplate.videolink = resultado_prueba.video;
              const resultValue = resultado_prueba.indicators_avg || 0;
              maxValueTest = Math.max(maxValueTest, resultValue);
            });
    
            testTemplate.value = maxValueTest;
            maxValueTotal = Math.max(maxValueTotal, maxValueTest);
    
            let colorsTest = getColorsForValue(maxValueTest);
            testTemplate.color = colorsTest.color;
            testTemplate.backgroundcolor = colorsTest.backgroundcolor;
    
            dataTemplate.tests.push(testTemplate);
          });
        }
    
        dataTemplate.value = maxValueTotal;
    
        let colors = getColorsForValue(maxValueTotal);
        dataTemplate.color = colors.color;
        dataTemplate.backgroundcolor = colors.backgroundcolor;
    
        data_final.push(dataTemplate);
      });
    
      return data_final;
    };
    

    const fetchPlayerSkills = async () => {
      const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
      const config = {
        headers: {
          Authorization: `${bearerToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.get(`${BACKEND_API_URL}/api/players/${playerId}/skills/`, config);
        if (response.status === 200) {
          setSkills(loadData(response.data));
        } else {
          throw new Error('Error al obtener los datos de habilidades');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPlayerSkills();
  }, [playerId, bearerToken]);


  const getColorsForValue = (value) => {
    if (value < 50) return { color: '#F80828', backgroundcolor: '#FF98A6' }; 
    if (value >= 50 && value <= 80) return { color: '#080838', backgroundcolor: '#C8D6FF' }; 
    return { color: '#28B35A', backgroundcolor: '#BEF4D1' };
  };

  const getColorForValue = (value) => {
    if (value < 50) return '#F80828'; 
    if (value >= 50 && value <= 80) return '#080838'; 
    return '#28B35A'; 
  }; 

  const renderDoughnutChart = (stat) => {
    const color = getColorForValue(stat.value); 
    const chartData = {
      labels: ['Porcentaje', 'Restante'],
      datasets: [
        {
          data: [stat.value, 100 - stat.value],
          backgroundColor: [color, '#e9ecef'], 
          hoverBackgroundColor: [color, '#e9ecef'],
          borderWidth: 0,
        },
      ],
    };

    const chartOptions = {
      maintainAspectRatio: false,
      cutout: '70%', 
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false, 
        },
      },
    };

    return (
      <DoughnutChartContainer>
        <Doughnut data={chartData} options={chartOptions} />
        <PercentageLabel>{`${stat.value}%`}</PercentageLabel>
      </DoughnutChartContainer>
    );
  };

  return (
    <>
    <TitleContainer>
    <h3 style={{ fontSize: '24px', color: '#080838' }}>Resumen de Estadísticas</h3>
    </TitleContainer>
    <StatisticsContainer>
      {skills.map((stat, index) => (
        <StatisticCard key={index}>
          {renderDoughnutChart(stat)}
          <StatisticInfo>
            <StatLabel>{stat.label}</StatLabel>
            {stat.tests.map((test, idx) => (
              <TestContainer>
                <FirstHalf>
                    <TestInfo key={idx}> 
                      <ThirdOne><TestName>{test.name}</TestName></ThirdOne>
                      <ThirdTwo>
                        <a href={test.videolink} target="_blank" rel="noopener noreferrer">
                          <Iconvideo />
                        </a>
                      </ThirdTwo>
                      <ThirdThree><TestValue color={getColorForValue(test.value)}>{test.value}%</TestValue></ThirdThree>
                    </TestInfo>
                </FirstHalf>
                <SecondHalf>
                    <ProgressBarContainer backgroundcolor="#e9ecef">
                      <ProgressBar value={test.value} color={getColorForValue(test.value)} />
                    </ProgressBarContainer>
                </SecondHalf>
              </TestContainer>
            ))}
          </StatisticInfo>
        </StatisticCard>
       
      ))}
    </StatisticsContainer>
    </>
  );
};

export default StatisticChart;

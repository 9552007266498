import styled from 'styled-components';
import Icon_sort from '../../components/assets/icons/Group20.svg'
import Icon_filter from '../../components/assets/icons/Vector.svg'

export const GlobalContainer = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const TableContainer = styled.div`
  flex: 1;
  padding: 20px; 
  margin: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  overflow-y: auto;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  gap: 50px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  gap:10px
`;

export const FilterSelectContainer = styled.div`
  position: relative;
  display: inline-block;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    width: 8px;
    height: 4px;
    background-image: url(${Icon_filter});
    background-size: cover;
    pointer-events: none;
  }
`;

export const FilterSelect = styled.select`
  width: 132px;
  height: 30px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  padding-left: 10px;
  border-radius: 20px;
  border: 1px solid #080838;
  cursor: pointer;
  flex-grow: 1;
`;

export const Text = styled.span`
  color: #080838;
  font-family: Inter;
  font-size: 21.33px;
  font-weight: 600;
  align-self: flex-start;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableHeader = styled.th`
  padding: 10px;
  border-top: 1px solid #6B7585;
  text-align: left;
  width: auto;

  &:first-child {
    width: 20%;
  }
  &:nth-last-child(2) {
    width: 12%; 
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto; 

  &:first-child {
    width: 20%; 
  }
  &:nth-last-child(2) {
    width: 12%;
  }
`;

export const ViewButton = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  background-color: #080838;
  color: white;
  cursor: pointer;
`;

export const SortButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 10px;
    transform: translateY(-85%) ${props => (props.direction === 'ascending' ? '' : 'rotate(180deg)')};
    width: 12px;
    height: 13.5px;
    background-image: url(${Icon_sort});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo} from '../../components/assets/icons/Logo MetaSports Blanco 3.0 1.svg'

export const GlobalContainer = styled.div`
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex: 1;
  overflow: hidden;
`;

export const FormContainer = styled.div`
  width: 25vw;
  margin: auto;
  border-radius: 15px;
  background: var(--grses-y-blancos-ffffff, #FFF);
  display: flex;
  flex-direction: column;
  justify-content: center;

  `;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding: 20px;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 34px;
  border: 1px solid var(--grses-y-blancos-808285, #6B7585);
  background: #FFF;
  width:70%;
`;

export const ButtonAccount = styled(NavLink)`
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  background-color: #080838; 
  color: white; 
  padding: 10px; 
  border-radius: 34px;
  &:hover {
    opacity: 0.9;
  }
  width:70%;
`;

export const ButtonCreateAccount = styled.button`
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  border-radius: 34px;
  border: 1px solid var(--grses-y-blancos-808285, #6B7585);
  width:70%;
`;

export const Text = styled.span`
  color: #080838;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;  
  padding-bottom: 10px;
`;

export const LinkText = styled.span`
  color: #080838;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Texty = styled.span`
  color: var(--neutral-azul-grisceo-102-a-43, #102A43);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 10px
`;
export const BlueSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const LogoStyled = styled(Logo)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
// SearchBarStyle.js
import styled from 'styled-components';
import SearchIcon from '../assets/icons/SearchIcon.svg';

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 20px;
  &:focus {
    outline: none;
  }
`;

export const SearchButton = styled.button`
  background: url(${SearchIcon}) no-repeat center center;
  background-size: contain; 
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px; 
  padding: 0;
  margin-right: 10px;
  outline: none;
`;

export const SearchResultList = styled.ul`
  position: absolute;
  top: 100%;
  z-index: 1000;
  width: 100%;
  background: white;
  list-style: none;
  margin-top: 2px;
  max-height: 200px;
  overflow-y: auto;
  border: 2px solid #606060;
  padding: 0;
  border-radius: 5px
`;

export const SearchResultItem = styled.li`
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid #ccc;
  
  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    background-color: #f2f2f2;
  }
`;
import styled from 'styled-components';
import Icon_filter from '../assets/icons/PerfilSetting.svg';

export const DropdownButton = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;

  &:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 4px;
    background-image: url(${Icon_filter});
    background-size: cover;
    pointer-events: none;
  }
`;

export default DropdownButton;

import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../../components/Shared/UserContext';
import { differenceInYears } from 'date-fns';
import {
  GlobalContainer,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  ViewButton,
  TableHeaderContainer,
  FilterSelect,
  Text,
  SortButton,
  TableContainer,
  FiltersContainer,
  FilterSelectContainer
} from './CandidatosStyle';

import { useNavigate } from 'react-router-dom';

const Candidatos = () => {
  const { bearerToken } = useUser();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'descending' });
  const [players, setPlayers] = useState([]);
  const [nationalitys, setNationalitys] = useState([]);
  const [genders, setGenders] = useState([]);
  const [positions, setPositions] = useState([]);
  const [ages, setAges] = useState([]);
  const [heights, setHeights] = useState([]);
  const [weights, setWeights] = useState([]);
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    genero: '',
    nacionalidad: '',
    edad: '',
    posicion: '',
    estatura: '',
    peso: '',
  });

  useEffect(() => {
    const fetchPlayers = async (token) => {
      const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
      const config = {
        headers: { Authorization: `${token}` }
      };

      try {
        const response = await axios.get(`${API_BASE_URL}/api/players/`, config);
        const result = response.data;
        const modifiedPlayers = result.map(player => ({
          ...player,
          playerattribute: {
            ...player.playerattribute,
            age: differenceInYears(new Date(), new Date(player.playerattribute.birthdate))
          }
        }));
        setPlayers(modifiedPlayers);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlayers(bearerToken);
  }, [bearerToken]);

  useEffect(() => {
    const estaturasUnicas = getUniqueNestedOptions(players, 'playerattribute', 'height');
    const pesosUnicos = getUniqueNestedOptions(players, 'playerattribute', 'weight');
    const edadesUnicas = getUniqueNestedOptions(players, 'playerattribute', 'age');
    const nacionalidadesUnicas = getUniqueNestedOptions(players, 'playerattribute', 'nationality');
    const posicionesUnicas = getUniqueNestedOptions(players, 'playerattribute', 'positionname', 'name');
    const generosUnicos = getUniqueNestedOptions(players, 'playerattribute', 'gender');
    setHeights(estaturasUnicas);
    setWeights(pesosUnicos);
    setAges(edadesUnicas);
    setGenders(generosUnicos);
    setNationalitys(nacionalidadesUnicas);
    setPositions(posicionesUnicas);
  }, [players]);

  const getUniqueNestedOptions = (data, parentKey, childKey, grandChildKey) => {
    if (grandChildKey) {
      return [...new Set(data.map(item => item[parentKey][childKey][grandChildKey]))];
    } else if (childKey) {
      return [...new Set(data.map(item => item[parentKey][childKey]))];
    } else {
      return [...new Set(data.map(item => item[parentKey]))];
    }
  };

  const requestSort = (key) => {
    setSortConfig(prevConfig => {
      const isAsc = prevConfig.key === key && prevConfig.direction === 'ascending';
      const newDirection = isAsc ? 'descending' : 'ascending';
      return {
        key,
        direction: newDirection
      };
    });
  };
  
  

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value
    }));
  };

  const filteredPlayers = players.filter(player => {
    const birthDate = new Date(player.playerattribute.birthdate);
    const currentDate = new Date();
    const age = differenceInYears(currentDate, birthDate);

    return (filter.genero ? player.playerattribute.gender === filter.genero : true) &&
           (filter.nacionalidad ? player.playerattribute.nationality === filter.nacionalidad : true) &&
           (filter.edad ? age === parseInt(filter.edad) : true) &&
           (filter.posicion ? player.playerattribute.positionname.name === filter.posicion : true) &&
           (filter.estatura ? player.playerattribute.height === parseInt(filter.estatura) : true) &&
           (filter.peso ? player.playerattribute.weight === parseInt(filter.peso) : true);
  });

  const sortedAndFilteredPlayers = useMemo(() => {
    let sortablePlayers = [...filteredPlayers];
    if (sortConfig.key !== null) {
      sortablePlayers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePlayers;
  }, [filteredPlayers, sortConfig]);

  const handleViewClick = (playerId) => {
    navigate(`/ficha/${playerId}`);
  };

  return (
    <GlobalContainer>
      <TableContainer>
        <TableHeaderContainer>
          <Text>Buscador de Jugadores</Text>
          <FiltersContainer>

            <FilterSelectContainer>
              <FilterSelect name="genero" value={filter.gender} onChange={handleFilterChange}>
                <option value="">Género</option>
                  {genders.map((gender, index) => (
                    <option key={index} value={gender}>{gender}</option>
                ))}
              </FilterSelect>
            </FilterSelectContainer>

            <FilterSelectContainer>
              <FilterSelect name="nacionalidad" value={filter.nacionalidad} onChange={handleFilterChange}>
                <option value="">Nacionalidad</option>
                {nationalitys.map((nationality, index) => (
                  <option key={index} value={nationality}>{nationality}</option>
                ))}
              </FilterSelect>
            </FilterSelectContainer>
            
            <FilterSelectContainer>
              <FilterSelect name="edad" value={filter.age} onChange={handleFilterChange}>
                <option value="">Edad</option>
                {ages.map((age, index) => (
                  <option key={index} value={age}>{age}</option>
                ))}
              </FilterSelect>
            </FilterSelectContainer>

            <FilterSelectContainer>
              <FilterSelect name="posicion" value={filter.posicion} onChange={handleFilterChange}>
                <option value="">Posición</option>
                {positions.map((position, index) => (
                  <option key={index} value={position}>{position}</option>
                ))}
              </FilterSelect>
            </FilterSelectContainer>

            <FilterSelectContainer>
              <FilterSelect name="estatura" value={filter.height} onChange={handleFilterChange}>
                <option value="">Estatura</option>
                  {heights.map((height, index) => (
                    <option key={index} value={height}>{height}</option>
                  ))}
              </FilterSelect>
            </FilterSelectContainer>

            <FilterSelectContainer>
              <FilterSelect name="peso" value={filter.weight} onChange={handleFilterChange}>
                <option value="">Peso</option>
                  {weights.map((weight, index) => (
                    <option key={index} value={weight}>{weight}</option>
                  ))}
              </FilterSelect>
            </FilterSelectContainer>
          </FiltersContainer>
        </TableHeaderContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>
                Nombre 
              <SortButton onClick={() => requestSort('lastname')} direction={sortConfig.key === 'lastname' ? sortConfig.direction : undefined} />
              </TableHeader>
              <TableHeader>Género</TableHeader>
              <TableHeader>Nacionalidad</TableHeader>
              <TableHeader>Edad</TableHeader>
              <TableHeader>Estatura</TableHeader>
              <TableHeader>Peso</TableHeader>
              <TableHeader>Posición</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredPlayers.map((player, index) => (
              <TableRow key={index}>
                <TableCell>{player.name + " " +  player.lastname}</TableCell>
                <TableCell>{player.playerattribute.gender}</TableCell>
                <TableCell>{player.playerattribute.nationality}</TableCell>
                <TableCell>{player.playerattribute.age}</TableCell>
                <TableCell>{player.playerattribute.height}</TableCell>
                <TableCell>{player.playerattribute.weight}</TableCell>
                <TableCell>{player.playerattribute.positionname.name}</TableCell>
                <TableCell><ViewButton onClick={() => handleViewClick(player.id)}>Ver Ficha</ViewButton></TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </GlobalContainer>
  );
};

export default Candidatos;

import styled from 'styled-components';
export const ProfileCard = styled.div`
  background: white;
  border-radius: 15px;
  display: grid;
  grid-template-columns: minmax(auto, max-content) 1fr;
  grid-template-rows: repeat(3, auto) auto; 
  grid-template-areas:
    "image info"
    "name name"
    "location location"
    ". chat";
  position: relative;
  padding: 20px;
  align-items: center; 
  justify-items: start; 
  grid-gap: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
  overflow: hidden;
`;

export const ProfileImage = styled.img`
  grid-area: image;
  width: 80px; 
  height: 80px; 
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px; 
`;

export const PlayerInfo = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #080838;
`;

export const PlayerName = styled.h3`
  grid-area: name;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #080838;
`;

export const LocationAndDateContainer = styled.div`
  grid-area: location;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ChatButton = styled.button`
  grid-area: chat;
  align-self: end; 
  justify-self: end; 
  background: red;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;


`;

export const PlayerDetail = styled.div`
  font-size: 0.9rem;
  display: flex;
  
  align-items: center;
  margin: 100px 0;
  color: #080838;
  text-align: start;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  overflow: hidden;

`;




export const PlayerDetailItem = styled.span`
  font-size: 0.9rem;
  color: #555;
  overflow: hidden;
`;



export const LocationDetail = styled(PlayerDetail)`
  color: var(--azul-principal-080838, #080838);
  overflow: hidden;
`;

export const DateDetail = styled(PlayerDetail)`
  color: var(--azul-principal-080838, #080838);
  overflow: hidden;
`;


export const Badge = styled.span`
  background-color: #f4f4f4;
  border-radius: 50%;
  color: var(--azul-principal-080838, #080838);
  padding: 10px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 20px;
  overflow: hidden;
`;

export const Stat = styled.div`
  color: var(--azul-principal-080838, #080838);
  font-size: 1rem;
  font-weight: bold;
  margin: 5px 0;
  overflow: hidden;
`;

export const SectionTitle = styled.h4`
  font-weight: bold; 
  margin: 0 0 4px 0; 
  color: var(--azul-principal-080838, #080838);
  font-size: 1rem; 
  overflow: hidden;
`;

export const SectionDetail = styled.p`
  margin: 0 0 12px 0; 
  color: var(--azul-principal-080838, #080838);
  font-size: 0.9rem; 
  overflow: hidden;
`;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlueSectionContainer, Text, LogoStyled, GlobalContainer, FormContainer, Form, Input, ButtonLogin, StyledLink } from './VerificadorStyle';
import { ReactComponent as BlueSectionSvg } from '../../components/assets/icons/Ellipse 1.svg'; 
import axios from 'axios';

const Verificador = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [verifycode, setVerifyCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerify = async (userId, verifycode) => {
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    try{
        const axiosResponse = await axios.post(`${BACKEND_API_URL}/api/users/${userId}/verify_email/`, {
          code: verifycode,
        });
        console.log(axiosResponse);
        
    } catch (error) {
        console.error('Error al verificar cuenta:', error);
        setErrorMessage(error.message);
    }
  };

  const Verify = (e) => {
    e.preventDefault();
    handleVerify(userId, verifycode);
    navigate('/login');
  };

  const handleEmail = async (userId) => {
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

    try{
        await axios.post(`${BACKEND_API_URL}/api/users/${userId}/send_verification_email/`, {});
  
    } catch (error) {
        console.error('Error al verificar cuenta:', error);
        setErrorMessage(error.message);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    handleEmail(userId);
  };

  return (
    <GlobalContainer>
      <FormContainer>
        <BlueSectionContainer>
          <BlueSectionSvg />
          <LogoStyled />
        </BlueSectionContainer>

        <Form onSubmit={Verify}>
          <Text>Verifique su cuenta</Text>
          <Text>El codigo llegara a su email</Text>
          
          <Input
            type="text"
            placeholder="Codigo de verificación"
            value={verifycode}
            onChange={(e) => setVerifyCode(e.target.value)}
          />
          <ButtonLogin type="submit">Verificar</ButtonLogin>
          <ButtonLogin onClick={sendEmail}>Enviar correo</ButtonLogin>
          <StyledLink to="/">Volver</StyledLink>
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>
          )}
        </Form>
      </FormContainer>
    </GlobalContainer>
  );
};

export default Verificador;

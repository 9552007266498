import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../components/Shared/UserContext';
import Chart from 'chart.js/auto';
import axios from 'axios';

const RadarChart = () => {
  const chartRef = useRef(null);
  const { playerId } = useParams();
  const { bearerToken } = useUser();
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchPlayerSkills = async (token) => {
      const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
      const config = {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.get(`${BACKEND_API_URL}/api/players/${playerId}/skills/`, config);

        if (response.status !== 200) {
          throw new Error('Error al obtener los datos de habilidades');
        }

        setSkills(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPlayerSkills(bearerToken);
  }, [playerId, bearerToken]);

  useEffect(() => {
    let myChart;
  
    if (skills.length > 0 && chartRef.current) {
      const myChartRef = chartRef.current.getContext('2d');
  
      if (myChart) {
        myChart.destroy();
      }
  
      const skillNames = skills.map(skill => skill.name);
      const skillScores = skills.map(skill => {
        const testsWithResults = skill.test_set.filter(test => test.testresult_set.length > 0);
        const scores = testsWithResults.flatMap(test =>
          test.testresult_set.flatMap(result =>
            result.indicators.map(indicator => indicator.score)
          )
        );
        return scores.length ? scores.reduce((a, b) => a + b, 0) / scores.length : 0;
      });
  
      myChart = new Chart(myChartRef, {
        type: 'radar',
        data: {
          labels: skillNames,
          datasets: [{
            label: 'Habilidades del Jugador',
            data: skillScores,
            fill: true,
            backgroundColor: '#FF98A6', 
            borderColor: '#FF98A6', 
            pointBackgroundColor: '#F80828',
            pointBorderColor: '#F80828',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
          }]
        },
        options: {
          scales: {
            r: {
              pointLabels: {
                font: {
                  size: 16, 
                  family: "Inter",
                },
                
              },
              ticks: {
                display: false,
                stepSize: 20, 
              },
              angleLines: {
                display: false
              },
              suggestedMin: 0,
              suggestedMax: 100,
              
              grid: {
                color: 'rgba(200, 200, 200, 0.8)'
              }
            }
          },
          elements: {
            line: {
              borderWidth: 3
            },
            point: {
              radius: 4,
              borderWidth: 2,
              hoverRadius: 6,
              hoverBorderWidth: 3
            }
          },
          plugins: {
            
            
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Habilidades',
              color: '#080838',
              font: {
                size: 25
              }
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              position: 'nearest',
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ": " + context.parsed.r + '%';
                }
              }
            },
            
            
            
          
          },
          responsive: true,
          maintainAspectRatio: false
        }
      });
    }
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [skills]);

  return <canvas ref={chartRef} />;
};

export default RadarChart;

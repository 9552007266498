import React from 'react';
import {
  FichaContainer,
  ProfileContainer,
  StatisticsContainer,
  LeftColumn, 
  RightColumn,
  RadarChartContainer
} from './FichaStyled';
import { GlobalContainer } from '../Candidatos/CandidatosStyle';
import ProfileSummary from './ProfileSummary/ProfileSummary';
import StatisticChart from './StatisticsSummary/StatisticChart';
import RadarChart from './Graphics/RadarChart';

const Ficha = () => {
  return (
    <GlobalContainer>
    <FichaContainer>
      <LeftColumn>
        <ProfileContainer>
          <ProfileSummary/>
        </ProfileContainer>
      <RadarChartContainer>
        <RadarChart/>
      </RadarChartContainer>
      </LeftColumn>
      <RightColumn>
        <StatisticsContainer>
            <StatisticChart/>
        </StatisticsContainer>
      </RightColumn>
    </FichaContainer> 
    </GlobalContainer>
  );
};

export default Ficha;

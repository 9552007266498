import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../components/Shared/UserContext';
import axios from 'axios';
import { 
  ProfileCard, 
  ProfileImage, 
  PlayerInfo, 
  PlayerName, 
  PlayerDetailItem, 
  LocationAndDateContainer,
  SectionDetail,
  SectionTitle,
  Stat,
  ChatButton 
} from './ProfileStyles';
import defaultProfileImage from '../../../components/assets/icons/default-profile-image.jpg';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const ProfileSummary = () => {
  const [player, setPlayer] = useState(null);
  const { playerId } = useParams();
  const { bearerToken } = useUser(); 

  useEffect(() => {
    const fetchPlayer = async (token) => {
      const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
      const config = {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json', 
        }
      };

      try {
        const response = await axios.get(`${BACKEND_API_URL}/api/players/${playerId}/`, config);

        if (response.status !== 200) {
          throw new Error('Error al obtener los datos del jugador');
        }

        setPlayer(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPlayer(bearerToken);
  }, [playerId, bearerToken]);


  if (!player) {
    return <div>Cargando...</div>; 
  }

  const calculateAge = (birthdate) => {
    const birthday = new Date(birthdate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'PPP', { locale: es });
  };

  return (
    <ProfileCard>
      <ProfileImage src={defaultProfileImage} alt="Foto de perfil" />
  
      <PlayerInfo>
        <Stat>Edad <br></br> <PlayerDetailItem>{calculateAge(player.playerattribute.birthdate)}</PlayerDetailItem></Stat>
        <Stat>Estatura <br></br> <PlayerDetailItem>{player.playerattribute.height} cm</PlayerDetailItem></Stat>
        <Stat>Posición <br></br> <PlayerDetailItem>{player.playerattribute.positionname.name}</PlayerDetailItem></Stat>
      </PlayerInfo>
      <PlayerName>{player.name} {player.lastname}</PlayerName>
      <LocationAndDateContainer>
        <SectionTitle>Locación</SectionTitle>
        <SectionDetail>{player.playerattribute.state}, {player.playerattribute.city}</SectionDetail>
        <SectionTitle>Usuario desde</SectionTitle>
        <SectionDetail>{formatDate(player.created)}</SectionDetail>
      </LocationAndDateContainer>
      <ChatButton>Chat</ChatButton>
    </ProfileCard>
  );
};

export default ProfileSummary;

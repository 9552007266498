import { createGlobalStyle } from "styled-components";

const InterStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

  body {
    font-family: 'Inter', sans-serif;
  }
`;

export default InterStyle;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueSectionContainer, LogoStyled, GlobalContainer, FormContainer, Form, Input, ButtonCreateAccount, ButtonAccount, Text, LinkText, TextContainer, Texty } from './SingUpFormStyle';
import { ReactComponent as BlueSectionSvg } from '../../components/assets/icons/Ellipse 1.svg';
import { GoogleButtonContainer } from '../../components/Shared/GoogleButtonContainerStyle';
import GoogleLoginButton from '../../components/Shared/GoogleLoginButton';
import axios from 'axios';

const SignUpForm = () => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleRegister = async (response, method = 'normal') => {
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    try {
      if (method === 'google') {
        const axiosResponse = await axios.post(`${BACKEND_API_URL}/api/google/auth/`, {
          token: response.credential
        });

        const { id } = axiosResponse.data;

        await axios.post(`${BACKEND_API_URL}/api/users/${id}/send_verification_email/`, {});

        navigate(`/verificar/${id}`);
      //-------------------------------------------------------//

      } else {
        try {
          const axiosResponse = await axios.post(`${BACKEND_API_URL}/api/users/`, {
            name: name,
            lastname: lastname,
            email: email,
            password: password,
            role: "scout"
          });

          const { id } = axiosResponse.data;

          await axios.post(`${BACKEND_API_URL}/api/users/${id}/send_verification_email/`, {});

          navigate(`/verificar/${id}`);
        } catch (error) {
          if (error.response) {
            console.log(error.response);
            try {
              if ('Ya existe user con este email.' === error.response.data.email[0]){
                setErrorMessage('Ya existe user con este email');
              }
            }catch(error){
              setErrorMessage('Error al crear cuenta con credenciales normales');
            }

          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        }
      }
    } catch (error) {
      console.error('Error al crear cuenta:', error);
      setErrorMessage(error.message);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Error al crear cuenta:', error);
    setErrorMessage(error.message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRegister({ name, lastname, email, password }, 'normal');
  };

  return (
    <GlobalContainer>
      <FormContainer>
        <BlueSectionContainer>
          <BlueSectionSvg />
          <LogoStyled />
        </BlueSectionContainer>
        <Form onSubmit={handleSubmit}>
          <GoogleButtonContainer>
            <GoogleLoginButton
                onSuccess={(response) => handleRegister(response, 'google')}
                onFailure={handleLoginFailure}
            />
          </GoogleButtonContainer>
          <ButtonAccount to="/login">Ya tengo cuenta</ButtonAccount>
          <Text>o</Text>
          <Input
            type="text"
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="text"
            placeholder="Apellido"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <Input
            type="email"
            placeholder="Correo"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextContainer>
              <Text>Al crear tu cuenta estás aceptando la </Text>
              <LinkText>Política de Privacidad</LinkText>
              <Texty>y</Texty>
              <LinkText>Términos de Uso</LinkText>
          </TextContainer>
          <ButtonCreateAccount type="submit">Crear cuenta</ButtonCreateAccount>
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>
          )}
        </Form>
      </FormContainer>
    </GlobalContainer>
  );
};

export default SignUpForm;

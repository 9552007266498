import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo} from '../../components/assets/icons/Logo MetaSports Blanco 3.0 1.svg'

export const GlobalContainer = styled.div`
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex: 1;
  overflow: hidden;
  `;

export const FormContainer = styled.div`
  width: 25vw;
  margin: auto;
  border-radius: 15px;
  background: var(--grses-y-blancos-ffffff, #FFF);
  display: flex;
  flex-direction: column;
  justify-content: center;

  `;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding: 20px;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 34px;
  border: 1px solid var(--grses-y-blancos-808285, #6B7585);
  background: #FFF;
  width:70%;
`;

export const ButtonLogin = styled.button`
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  background-color: #080838; 
  color: white; 
  padding: 10px; 
  border-radius: 34px;

  &:hover {
    opacity: 0.9;
  }
  width:70%;
`;

export const StyledLink = styled(NavLink)`
  color: #080838;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
`;

export const BlueSectionContainer = styled.div`
  border-radius: 15px;
  border-top-left-radius: 15px;

  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; 
`;


export const LogoStyled = styled(Logo)`

  width: auto;
  height: auto; 
  margin: 0 auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// NavBarStyles.js
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Text = styled.span`
  color: #080838;
  text-align: left;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em; 
`;

export const TextVariant1 = styled(Text)`
  font-style: italic;
  padding-right: 5px;
  padding-left: 5px;
`;

export const NavBarContainer = styled.nav`
  background: var(--Grses-y-Blancos-F5F5F4, #F9F9F9);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;

  background-color: #f2f2f2;
`;

export const NavItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 48px;
`;

export const HomeLink = styled(NavLink)`
  color: var(--rojos-d-1041-f, #D1041F);
  font-size: 16px; // Letra más grande para Home
  font-weight: 600; // Letra más gruesa para Home
  line-height: normal;
  text-decoration: none;
`;

export const StyledLink = styled(NavLink)`
  color: #080838;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

  &.disabled {
    color: #ccc;
    pointer-events: none;
    cursor: default;
  }
`;

export const RegisterButton = styled(NavLink)`
  text-decoration: none;
  background-color: #080838;
  color: white;
  padding: 10px;
  border-radius: 21.5px;
  flex-shrink: 0;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`;

export const AuthLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 30px;
`;

export const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
`;
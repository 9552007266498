import styled from 'styled-components';

export const RadarChartContainer = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  overflow: hidden;
`;
export const GlobalContainer = styled.div`
  background-color: #f2f2f2;
  width: 100%;
  padding: 20px;
  display: flex;
  overflow: hidden;
  justify-content: center;
`;

export const FichaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
  flex: 1;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;


export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;
`;


export const ProfileContainer = styled.section`
  background: #fff;
  border-radius: 15px;
  background: var(--grses-y-blancos-ffffff, #FFF);
  margin-bottom: 20px;
  overflow: hidden;
`;

export const StatisticsContainer = styled.section`
  border-radius: 15px;
  background: var(--grses-y-blancos-ffffff, #FFF);
  max-width: 100%;
  overflow: hidden;
  padding: 10px;
  overflow: hidden;
`;


export const SkillsContainer = styled.section`
  grid-row: 1 / 2; 
  justify-content: space-around;
  padding: 20px;
  border-radius: 15px;
  background: var(--grses-y-blancos-ffffff, #FFF);
  overflow: hidden;
`;



// StatisticStyles.js
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const StatisticTitle = styled.span`
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
`;

export const DoughnutChartContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
`;

export const PercentageLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  font-weight: bold;
  overflow: hidden;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${props => props.backgroundcolor};;
  border-radius: 15px;
  overflow: hidden;
`;

export const ProgressBar = styled.div`
  height: 12px;
  border-radius: 15px;
  background-color: ${props => props.color};
  width: ${props => props.value}%;
  overflow: hidden;
`;

export const StatisticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
`;

export const StatisticCard = styled.div`
  background: #fff;
  border-radius: 8px;
  margin: 10px;
  text-align: center;
  overflow: hidden;
`;

export const StatLabel = styled.h2`
  color: #333;
  font-size: 1.2em;
  margin: 10px 0;
  text-align: center;
  overflow: hidden;
`;

export const StatisticInfo = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
`;

export const TestContainer = styled.div`
  padding: 5px 10px;
  overflow: hidden;
`;

export const FirstHalf = styled.div`
  display: flex;
  flex-direction: column; 
  flex: 1;
  overflow: hidden;
`;

export const ThirdOne = styled.div`
  flex: 3; 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ThirdTwo = styled.div`
  flex: 1; 
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ThirdThree = styled.div`
  flex: 2; // Tomará tres veces el espacio base
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SecondHalf = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TestInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;



export const TestName = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

export const TestValue = styled.span`
  color: ${props => props.color};
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;


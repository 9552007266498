import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/Shared/NavBar';
import Candidatos from './views/Candidatos/Candidatos'
import Login from './views/Login/Login';
import Register from './views/Register/SingUpForm'
import Ficha from './views/Ficha/Ficha'
import Verificador from './views/Verify/Verificador'
//import { ProtectedRoute } from './components/Shared/ProtectRoute'
import './App.css';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables); 

const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<Login />} />     
        <Route path="/verificar/:userId" element={<Verificador />} />
        {/*<Route path="/candidatos" element={<ProtectedRoute> <Candidatos /> </ProtectedRoute>} />*/}
        <Route path="/candidatos" element={<Candidatos />} />
        {/*<Route path="/ficha/:playerId" element={<ProtectedRoute> <Ficha /> </ProtectedRoute>} />*/}
        <Route path="/ficha/:playerId" element={<Ficha />} />
        </Routes>
    </Router>
  );
};
export default App;


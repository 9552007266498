import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../components/Shared/UserContext';
import { BlueSectionContainer, LogoStyled, GlobalContainer, FormContainer, Form, Input, ButtonLogin, StyledLink } from './LoginStyle';
import { ReactComponent as BlueSectionSvg } from '../../components/assets/icons/Ellipse 1.svg'; 
import GoogleLoginButton from '../../components/Shared/GoogleLoginButton';
import { GoogleButtonContainer } from '../../components/Shared/GoogleButtonContainerStyle';
import axios from 'axios';

const Login = () => {
  const { setUser, setBearerToken, saveUserToLocalStorage, setUserVerified } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (response, method = 'normal') => {
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    try {
      if (method === 'google') {  
        const axiosResponse = await axios.post(`${BACKEND_API_URL}/api/google/auth/`, {
          token: response.credential
        });

        const token = axiosResponse.headers.authorization;
        const userInfo = axiosResponse.data;
        const { id, name: userName, lastname: userLastname, email: userEmail, role, verified: verifiedUser } = axiosResponse.data;
        setUser({ id, name: userName, lastname: userLastname, email: userEmail, role, verified: verifiedUser });
        setUserVerified(verifiedUser);
        setBearerToken(token);

        saveUserToLocalStorage(userInfo, token)
        navigate('/candidatos');
      //-------------------------------------------------------//

      } else {
        const axiosResponse = await axios.post(`${BACKEND_API_URL}/api/login/`, {
          email: response.email,
          password: response.password
        });

        const token = axiosResponse.headers.authorization;
        const userInfo = axiosResponse.data;
        const { id, name: userName, lastname: userLastname, email: userEmail, role, verified: verifiedUser } = axiosResponse.data;
        setUser({ id, name: userName, lastname: userLastname, email: userEmail, role, verified: verifiedUser });
        setUserVerified(verifiedUser);
        setBearerToken(token);

        saveUserToLocalStorage(userInfo, token)
        navigate('/candidatos');
      }
    } catch (error) {
      console.log(error)
      console.error('Error al iniciar sesión:', error);
      setErrorMessage(error.message)
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Error al iniciar sesión:', error);
    setErrorMessage(error.message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin({ email, password }, 'normal');
  };

  return (
    <GlobalContainer>
      <FormContainer>
        <BlueSectionContainer>
          <BlueSectionSvg />
          <LogoStyled />
        </BlueSectionContainer>
        <Form onSubmit={handleSubmit}>
          <GoogleButtonContainer>
            <GoogleLoginButton 
              onSuccess={(response) => handleLogin(response, 'google')} 
              onFailure={handleLoginFailure} 
            />
          </GoogleButtonContainer>
          <Input
            type="text"
            placeholder="Correo"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ButtonLogin type="submit">Ingresar</ButtonLogin>
          <StyledLink to="/">Volver</StyledLink>
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>
          )}
        </Form>
      </FormContainer>
    </GlobalContainer>
  );
};

export default Login;

// SearchBar.js
import React, { useState, useRef, useEffect } from 'react';
import { SearchButton, SearchInput, SearchBarContainer, SearchResultList, SearchResultItem } from './SearchBarStyle';
import { useUser } from '../../components/Shared/UserContext';
import axios from 'axios';
import { differenceInYears } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
    const { bearerToken } = useUser();
    const [ players, setPlayers ] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const searchRef = useRef(null);


    useEffect(() => {
        const fetchPlayers = async (token) => {
        const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const response = await axios.get(`${API_BASE_URL}/api/players/`, config);
            const result = response.data;
            const modifiedPlayers = result.map(player => ({
                ...player,
                playerattribute: {
                ...player.playerattribute,
                age: differenceInYears(new Date(), new Date(player.playerattribute.birthdate))
                }
        }));
        setPlayers(modifiedPlayers);
        } catch (error) {
        console.log(error);
        }
    };
    
        fetchPlayers(bearerToken);
    }, [bearerToken]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchTerm('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredPlayers = players.filter(player =>{
        let fullname = player.name + " " + player.lastname;
        return fullname.toLowerCase().includes(searchTerm.toLowerCase());
        }
    );

    const handleItemClick = (playerId) => {
        navigate(`/ficha/${playerId}`);
        setSearchTerm('');
      };

    return (
        <SearchBarContainer ref={searchRef}>
            <SearchInput type="text" placeholder="Buscar jugador" value={searchTerm} onChange={handleSearchChange} />
            <SearchButton type="submit"/>
            {searchTerm && (
                <SearchResultList>
                    {filteredPlayers.map((player, index) => (
                        <SearchResultItem key={index} onClick={() => handleItemClick(player.id)}>
                            {player.name + " " + player.lastname}
                        </SearchResultItem>
                    ))}
                </SearchResultList>
            )}
        </SearchBarContainer>
    );
};

export default SearchBar;

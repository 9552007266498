import React, { useEffect } from 'react';

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  useEffect(() => {

    const handleCredentialResponse = (response) => {
      try {
          onSuccess(response);
      } catch (error) {
        onFailure(error);
      }
    };

    const initGoogleSignIn = () => {
      window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButton"),
        {
          theme: "outline",
          size: "large",
          shape: "pill",
          logo_alignment: "left",
        }
      );    
    };

    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = initGoogleSignIn;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [onSuccess, onFailure]);

  return (
    <div id="googleSignInButton"></div>
  );
};

export default GoogleLoginButton;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { NavBarContainer, Text, TextVariant1, NavItems,  StyledLink, AuthLinksContainer, RegisterButton, ProfileContainer, ProfileImage} from './NavBarStyles';
import { ReactComponent as Logo } from '../assets/icons/LogoMetaSports.svg';
import { ReactComponent as BellIcon } from '../assets/icons/BellIcon.svg';
import { useUser } from './UserContext';
import { DropdownButton } from './DropdownButton';
import { DropdownMenuContainer, DropdownItem } from './DropdownStyles';
import SearchBar from './SearchBar';
import defaultProfileImage from '../assets/icons/default-profile-image.jpg'

const NavBar = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const isLinkDisabled = !!user;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggleRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownToggleRef.current && !dropdownToggleRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleLogout = () => {
    logout();
    setIsDropdownOpen(false);
    navigate('/');
  };

  return (
    <NavBarContainer>
      <Link to="/">
        <Logo />
      </Link>
      <NavItems>
      <li>
        {isLinkDisabled ? (
          <StyledLink to="/candidatos" activeClassName="active">Candidatos</StyledLink>
        ) : (
          <StyledLink as="div" className="disabled">Candidatos</StyledLink>
        )}
      </li> 
      </NavItems>

      <AuthLinksContainer ref={dropdownToggleRef}> 
        {user && (
          <>
            <SearchBar />
            <ProfileContainer>
              <BellIcon />
              <ProfileImage src={defaultProfileImage} alt="Perfil del usuario" />
              <Text>Hola,</Text>    <TextVariant1> coach</TextVariant1>     <Text> {user.name}</Text>
              <DropdownButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}></DropdownButton>
            </ProfileContainer>
            {isDropdownOpen && (
              <DropdownMenuContainer>
                <DropdownItem onClick={handleLogout}>Cerrar sesión </DropdownItem>
              </DropdownMenuContainer>
            )}
          </>
        )}
        {!user && (
          <>
            <StyledLink to="/login">Ingresar</StyledLink>
            <RegisterButton to="/">Regístrate</RegisterButton>
          </>
        )}
      </AuthLinksContainer>
    </NavBarContainer>
  );
};

export default NavBar;
